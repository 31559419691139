<template>
  <div class="wrapper-auth">
    <section class="login">
      <div class="login-register">
        <div class="login-register_overlay"></div>
        <div class="login-card">
          <div class="logo">
            <div class="logo-img">
              <img src="@/assets/img/paw-logo.png" alt="" />
            </div>
            <p class="logo-title logo-title_login">KOREA VISA PORTAL</p>
          </div>
          <div class="form">
            <div class="form-input">
              <input type="text" placeholder="Tên đăng nhập" v-model="username" />
              <input type="password" placeholder="Mật khẩu" v-model="pwd" />
            </div>

            <!-- <p class="find-password">Quên mật khẩu?</p> -->
            <div class="flex justify-center">
              <button class="login-btn" @click="login">Đăng nhập</button>
            </div>
          </div>
          <p class="title-register">
            Chưa có tài khoản? <span class="text-register"><router-link to="/auth/register">Đăng ký</router-link></span>
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import network from "@/core/network";
export default {
  data() {
    return {
      username: '',
      pwd: '',
      showPwd: false
    }
  },
  methods: {
    login() {
      if (!this.username || !this.pwd) {
        this.$toast.open({
          message: 'Vui lòng điền đầy đủ thông tin !',
          type: 'error',
          position: 'top',
          duration:3000,
        });
        return false;
      }
      network.post('/admin/login', {
        name: this.username,
        password: this.pwd
      }, { withCredentials: true })
      .then(response => {
          localStorage.setItem('access_token', response.data.access_token)
          localStorage.setItem('role', response.data.role)
          localStorage.setItem('userId', response.data._id)
          this.$toast.open({
            message: 'Đăng nhập thành công !',
            type: 'success',
            position: 'top',
            duration:3000,
          });
          setTimeout(() => {
            this.$router.push('/home')
          }, 1000)
      })
      .catch(error => {
        console.log(error);
        this.$toast.open({
          message: 'Tài khoản hoặc mật khẩu không đúng !',
          type: 'error',
          position: 'top',
          duration:3000,
        });
      });
    }
  }
};
</script>

<style>
input {
  background: white !important
}
</style>
